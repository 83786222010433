<template>
  <div class="service-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="service-detail__loader">
        <base-loader class="service-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="service-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else class="service-detail__inner">
        <div class="service-detail__content">
          <layout-title
            :title="current.title"
            :specs="specsObj"
            :cost="periodCost"
            :status="current.status"
            class="service-detail__head"
          >
          </layout-title>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="current" :tariff="current" :module-main="moduleMain"></router-view>
          </transition>
        </div>
        <!--        <main-card v-if="!isMobile" class="service-detail__aside">-->
        <!--          <domains-context-menu-->
        <!--            :tariff="current"-->
        <!--            :menu="contextMenu"-->
        <!--            :module-main="moduleMain"-->
        <!--            :tools="tools"-->
        <!--          />-->
        <!--        </main-card>-->
      </div>
    </transition>
  </div>
</template>

<script>
import LayoutTitle from '@/components/LayoutTitle/LayoutTitle.vue';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import breakpoint from '@/utils/breakpoints';
import tabs from '@/components/Tabs/Tabs.vue';
// import storeMixin from '@/layouts/Virtual/mixins';
// import DomainsContextMenu from '../../components/DomainsContextMenu.vue';
import dnsServiceContextMenu from '@/layouts/Domains/mixins/dnsServiceContextMenu';
export default {
  name: 'DnsServiceDetail',
  components: {
    LayoutTitle,
    tabs,
    BaseAlert,
    // DomainsContextMenu,
  },
  mixins: [dnsServiceContextMenu],
  data() {
    return {
      isCurrentLoading: true,
      isServerError: false,
      isMobile: true,
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
      nav: [
        {
          title: this.$t('nav.dns'),
          to: { name: 'dnsServiceInfo' },
        },
        {
          title: this.$t('nav.history'),
          to: { name: 'dnsServiceHistory' },
        },
      ],
    };
  },
  computed: {
    periodCost() {
      return this.$n(this.current.period_cost, 'currency', this.$i18n.locale);
    },
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.tools;
    },
    list() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.dnsList;
    },
    isLoading() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.isLoading;
    },
    current() {
      return this.list[0];
    },
    specsObj() {
      const specs = this.current.specs;
      let list = [];
      // list.push(
      //   this.$n(specs.cost, 'currency') +
      //     ' / ' +
      //     this.$tc(
      //       `period.${specs.periodTransformed.periodType}_c`,
      //       specs.periodTransformed.periodLen
      //     )
      // );
      list.push(this.$d(specs.expiredate, 'short'));
      return {
        list,
      };
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  created() {
    if (!this.list.length) {
      this.fetchList()
        .then(() => {
          this.fetchCurrent();
        })
        .catch(e => {
          this.isCurrentLoading = false;
          if (e.status && e.status === 520) this.isServerError = true;
        });
    } else if (this.checkNeedTariff()) {
      this.fetchCurrent();
    } else {
      this.isCurrentLoading = false;
    }
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/fetchList');
    },
    checkNeedTariff() {
      return !this.current.id || this.current.id !== this.$route.params.id;
    },
    fetchCurrent(id = this.$route.params.id) {
      this.isCurrentLoading = true;
      this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/fetchDetail', { id }).finally(() => {
        this.isCurrentLoading = false;
      });
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "back": "Назад",
    "nav": {
      "view": "Обзор",
      "whois": "Whois",
      "history": "История",
      "dns": "DNS-записи",
      "ns": "Серверы имён (NS)"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$dd-aside-size = 260px;
.service-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $dd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $dd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.service-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
