export default {
  computed: {
    dnsMgrTools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.tools;
    },
    contextMenu() {
      if (!this.current) return [];
      const { gotoserver } = this.tools;
      const { edit } = this.dnsMgrTools;
      const arr = [
        // {
        //   key: 'dns',
        //   attrs: {
        //     to: {
        //       name: 'dnshostDns',
        //       params: { id: this.current.id, domain: this.current.domain },
        //     },
        //   },
        // },
        {
          key: 'gotoserver',
          tool: gotoserver,
          // disabled: !gotoserver.isEnable(this.current),
        },
      ];
      // if (this.dnsMgrTools && edit) {
      //   arr.push({
      //     key: 'edit',
      //     tool: edit,
      //     disabled: !edit.isEnable(this.current),
      //   });
      // }
      // if (this.dnsMgrTools && this.dnsMgrTools.delete) {
      //   arr.push({
      //     key: 'delete',
      //     color: 'del',
      //     tool: this.dnsMgrTools.delete,
      //     disabled: !this.dnsMgrTools.delete.isEnable(this.current),
      //     askSure: true,
      //   });
      // }
      return [arr];
    },
  },
};
